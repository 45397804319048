import tw, { styled } from "twin.macro"

import { GlobalStyles } from "./Global"

const styles = {
  default: tw`border-t border-grey max-w-full w-full`,
}

export const StyledRule = styled.hr`
  ${styles.default}
  ${({ colour }) => GlobalStyles.border[colour] || GlobalStyles.border[`default`]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
