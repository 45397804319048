import React, { memo, useMemo } from "react"

import { useCustomerOrder } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountOrdersItem = Component =>
  memo(({ name = "AccountOrdersItem", id, link: rawLink, page, token }: any) => {
    const { order, loading, error } = useCustomerOrder(id, token)
    const { linkResolver } = useRoutes()

    const link = linkResolver(rawLink)

    Component.displayName = name
    return useMemo(() => (!loading ? <Component {...order} error={error} link={link} locales={page} /> : null), [error, loading, link, page, order])
  })
