import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountOrders } from "./withAccountOrders"
import { Icon } from "../../Icon/Icon"
import { AccountHeader } from "../Header/AccountHeader"
import { AccountOrdersItem } from "./Item/AccountOrdersItem"
import { AccountOrdersList } from "./List/AccountOrdersList"
import { StyledContainer } from "../../Styled/Container"
import { StyledColumn } from "../../Styled/Column"
import { H3 } from "../../Styled/Text"
import { Page, Header, Link } from "./styledAccountOrders"

export const AccountOrders = withAccountOrders(
  ({ error, id, link, loading, orders, page, template, token }): JSX.Element => (
    <>
      <AccountHeader location={location} page={page} template={template} />
      <Page>
        <StyledContainer width={`md`}>
          <Header gutter={`sm`} items={`center`}>
            <StyledColumn gutter={`sm`} justify={`start`} width={`1/6`}>
              {id ? (
                <Link as={GatsbyLink} colour={`purple-dark`} title={page?.additionalBack} to={link}>
                  <Icon name={`left`} title={page?.additionalBack} />
                </Link>
              ) : null}
            </StyledColumn>
            <StyledColumn gutter={`sm`} width={`2/3`}>
              <H3 as={`h1`} align={`center`}>
                {id ? page?.additionalDetail : page?.title}
              </H3>
            </StyledColumn>
            <StyledColumn gutter={`sm`} width={`1/6`} />
          </Header>
          {id ? (
            <AccountOrdersItem id={id} link={page?.help} page={page} token={token} />
          ) : (
            <AccountOrdersList error={error} link={page?.link} loading={loading} orders={orders} page={page} />
          )}
        </StyledContainer>
      </Page>
    </>
  )
)
