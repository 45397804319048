import React, { memo, useMemo } from "react"

import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountOrdersList = Component =>
  memo(({ name = "AccountOrdersList", error, loading, link: rawLink, orders, page }: any) => {
    const { linkResolver } = useRoutes()

    const link = linkResolver(rawLink)

    Component.displayName = name
    return useMemo(
      () => (!loading ? <Component error={error} link={link} locales={page} orders={orders} /> : null),
      [error, link, loading, page, orders]
    )
  })
