import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountOrdersList } from "./withAccountOrdersList"
import { StyledErrors } from "../../../Styled/Form"
import { StyledColumn } from "../../../Styled/Column"
import { StyledButton } from "../../../Styled/Button"
import { StyledImage } from "../../../Styled/Image"
import { StyledRow } from "../../../Styled/Row"
import { H4, H5, Overline, P } from "../../../Styled/Text"
import { Order, Button, Image, ImageOverlay, ActionButton } from "./styledAccountOrdersList"

export const AccountOrdersList = withAccountOrdersList(
  ({ error, link, locales, orders }): JSX.Element => (
    <>
      {orders?.length > 0 ? (
        orders?.map(({ cancelled, canceledAt, fulfilled, id, lineItems, name, processedAt, statusUrl, url }) => (
          <Order key={id?.toString()}>
            <StyledRow gutter={`sm`} withWrap>
              <StyledColumn gutter={`sm`} width={`md:2/3`}>
                <StyledRow gutter={`xs`} withSpacing withWrap>
                  <StyledColumn gutter={`xs`} width={`md:1/2`}>
                    <Overline colour={`purple-dark`} large>
                      {cancelled
                        ? locales?.additionalStatusCancelled
                        : fulfilled
                        ? locales?.additionalStatusFulfilled
                        : locales?.additionalStatusUnfulfilled}
                    </Overline>
                  </StyledColumn>
                  <StyledColumn gutter={`xs`} screen={`md>`} width={`md:1/2`}>
                    <P>
                      <strong>{locales?.additionalNumber}</strong>
                    </P>
                    <P>{name}</P>
                  </StyledColumn>
                </StyledRow>
                <StyledRow justify={`start`} gutter={`xxs`}>
                  {lineItems?.slice(0, 3)?.map(({ colour, id, image, title }, index: number) => (
                    <StyledColumn key={id?.toString()} gutter={`xxs`} width={`1/3 md:1/4`}>
                      <Image colour={`${colour}-pastel`}>
                        <StyledImage alt={title} fluid={{ ...image, aspectRatio: 1 / 1 }} />
                        {index === 2 && lineItems?.length > index ? (
                          <ImageOverlay>
                            <H4 colour={`white`}>+{lineItems?.length - index}</H4>
                          </ImageOverlay>
                        ) : null}
                      </Image>
                    </StyledColumn>
                  ))}
                </StyledRow>
              </StyledColumn>
              <StyledColumn gutter={`sm`} screen={`md>`} width={`md:1/3`}>
                <P>
                  <strong>{cancelled ? locales?.additionalDateCancelled : locales?.additionalDateOrdered}</strong>
                </P>
                <P withSpacing>{cancelled ? canceledAt : processedAt}</P>
                <StyledButton as={GatsbyLink} colour={`purple`} full size={`secondary`} title={locales?.additionalView} to={url} withSpacing={`xs`}>
                  {locales?.additionalView}
                </StyledButton>
                <StyledButton
                  as={`a`}
                  colour={`purple-outline`}
                  href={statusUrl}
                  full
                  rel={`noopener`}
                  size={`secondary`}
                  target={`_blank`}
                  title={locales?.additionalTrack}
                >
                  {locales?.additionalTrack}
                </StyledButton>
              </StyledColumn>
              <StyledColumn gutter={`sm`} screen={`<sm`} width={`md:1/3`}>
                <P>
                  <strong>{locales?.additionalNumber}</strong> {name}
                </P>
                <P withSpacing={`sm`}>
                  <strong>{cancelled ? locales?.additionalDateCancelled : locales?.additionalDateOrdered}</strong>{" "}
                  {cancelled ? canceledAt : processedAt}
                </P>
                <StyledRow gutter={`sm`}>
                  <StyledColumn gutter={`sm`} width={`1/2`}>
                    <ActionButton as={GatsbyLink} colour={`purple`} full size={`tertiary`} title={locales?.additionalView} to={url}>
                      {locales?.additionalView}
                    </ActionButton>
                  </StyledColumn>
                  <StyledColumn gutter={`sm`} width={`1/2`}>
                    <ActionButton
                      as={`a`}
                      colour={`purple-outline`}
                      href={statusUrl}
                      full
                      rel={`noopener`}
                      size={`tertiary`}
                      target={`_blank`}
                      title={locales?.additionalTrack}
                    >
                      {locales?.additionalTrack}
                    </ActionButton>
                  </StyledColumn>
                </StyledRow>
              </StyledColumn>
            </StyledRow>
          </Order>
        ))
      ) : (
        <>
          <H5 align={`center`} colour={`grey-darker`} large withSpacing={`lg`}>
            {locales?.additionalEmpty}
          </H5>
          {link?.url && (
            <StyledRow justify={`center`}>
              <Button as={GatsbyLink} colour={`purple`} size={`primary`} title={link?.title} to={link?.url}>
                {link?.title}
              </Button>
            </StyledRow>
          )}
        </>
      )}
      {error && <StyledErrors>{error?.toString()}</StyledErrors>}
    </>
  )
)
