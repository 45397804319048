import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledButton } from "../../../Styled/Button"

export const Order = tw.figure`border-t border-grey pt-4.5 pb-6 md:pt-5.75 md:pb-8 last:pb-0`
export const Button = tw(StyledButton)`md:w-102.5`
export const ActionButton = tw(StyledButton)`border-2 text-base py-0.5`
export const Image = styled.div`
  ${tw`relative bg-purple-dark-pastel -mt-5 mb-3 md:my-0`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
`
export const ImageOverlay = tw.div`absolute bg-black bg-opacity-25 w-full h-full inset-0 flex flex-col items-center justify-center`
