import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountOrdersItem } from "./withAccountOrdersItem"
import { Icon } from "../../../Icon/Icon"
import { StyledErrors } from "../../../Styled/Form"
import { StyledLink } from "../../../Styled/Button"
import { StyledColumn } from "../../../Styled/Column"
import { StyledImage } from "../../../Styled/Image"
import { StyledRow } from "../../../Styled/Row"
import { StyledRule } from "../../../Styled/Rule"
import { H6, Overline, P, Small, RichText } from "../../../Styled/Text"
import { Order, Image, Help, Link, Button, Colon } from "./styledAccountOrdersItem"

export const AccountOrdersItem = withAccountOrdersItem(
  ({
    cancelled,
    canceledAt,
    error,
    fulfilled,
    invoice,
    lineItems,
    link,
    locales,
    name,
    processedAt,
    shippingAddress,
    shipping,
    statusUrl,
    subtotal,
    total,
  }): JSX.Element => (
    <Order>
      <StyledRow gutter={`sm`} items={`center`} withSpacing withWrap>
        <StyledColumn gutter={`sm`} width={`md:2/3`}>
          <StyledRow gutter={`xs`} withWrap>
            <StyledColumn gutter={`xs`}>
              <Overline colour={`purple-dark`} large withSpacing={`xxs`}>
                {cancelled
                  ? locales?.additionalStatusCancelled
                  : fulfilled
                  ? locales?.additionalStatusFulfilled
                  : locales?.additionalStatusUnfulfilled}
              </Overline>
              <P withSpacing={`sm`}>
                {cancelled
                  ? locales?.additionalStatusCancelledDescription
                  : fulfilled
                  ? locales?.additionalStatusFulfilledDescription
                  : locales?.additionalStatusUnfulfilledDescription}
              </P>
            </StyledColumn>
            <StyledColumn gutter={`xs`} width={`md:1/2`} screen={`md>`}>
              <P>
                <strong>{locales?.additionalNumber}</strong>
              </P>
              <P>{name}</P>
            </StyledColumn>
            <StyledColumn gutter={`xs`} width={`md:1/2`} screen={`md>`}>
              <P>
                <strong>{cancelled ? locales?.additionalDateCancelled : locales?.additionalDateOrdered}</strong>
              </P>
              <P>{cancelled ? canceledAt : processedAt}</P>
            </StyledColumn>
            <StyledColumn gutter={`xs`} screen={`<sm`}>
              <P>
                <strong>{locales?.additionalNumber}</strong> {name}
              </P>
              <P withSpacing={`sm`}>
                <strong>{cancelled ? locales?.additionalDateCancelled : locales?.additionalDateOrdered}</strong>{" "}
                {cancelled ? canceledAt : processedAt}
              </P>
            </StyledColumn>
          </StyledRow>
        </StyledColumn>
        <StyledColumn gutter={`sm`} screen={`md>`} width={`md:1/3`}>
          <Button
            as={`a`}
            colour={`purple`}
            href={statusUrl}
            full
            rel={`noopener`}
            size={`secondary`}
            target={`_blank`}
            title={locales?.additionalTrack}
            withSpacing={`xxs`}
          >
            {locales?.additionalTrack}
          </Button>
          {invoice && (
            <Button
              as={`a`}
              colour={`purple-outline`}
              full
              href={invoice}
              rel={`noopener`}
              size={`secondary`}
              target={`_blank`}
              title={locales?.additionalInvoice}
            >
              <Icon name={`download`} width={12} />
              <span>{locales?.additionalInvoice}</span>
              <span />
            </Button>
          )}
        </StyledColumn>
        <StyledColumn gutter={`sm`} screen={`<sm`} width={`md:1/3`}>
          <StyledRow gutter={`sm`} withWrap>
            <StyledColumn gutter={`sm`} width={`1/2`}>
              <Button
                as={`a`}
                colour={`purple`}
                href={statusUrl}
                full
                rel={`noopener`}
                size={`tertiary`}
                target={`_blank`}
                title={locales?.additionalTrack}
                withSpacing={`xxs`}
              >
                {locales?.additionalTrack}
              </Button>
            </StyledColumn>
            <StyledColumn gutter={`sm`} width={`1/2`}>
              {invoice && (
                <Button
                  as={`a`}
                  colour={`purple-outline`}
                  full
                  href={invoice}
                  rel={`noopener`}
                  size={`secondary`}
                  target={`_blank`}
                  title={locales?.additionalInvoice}
                >
                  <Icon name={`download`} width={12} />
                  <span>{locales?.additionalInvoice}</span>
                  <span />
                </Button>
              )}
            </StyledColumn>
          </StyledRow>
        </StyledColumn>
      </StyledRow>
      <StyledRule withSpacing={`sm`} />
      <Overline large withSpacing={`xs`}>
        {lineItems?.length} {lineItems?.length === 1 ? locales?.additionalCount : locales?.additionalCountPlural}
        <Colon>:</Colon>
      </Overline>
      <StyledRow justify={`start`} gutter={`xs`} withSpacing={`xs`} withWrap>
        {lineItems?.map(({ colour, id, image, link, type, quantity, title, variants }) => (
          <StyledColumn key={id?.toString()} gutter={`xs`} width={`1/2 md:1/3`}>
            <Link as={GatsbyLink} title={title} to={link?.url}>
              <Image colour={`${colour}-pastel`}>
                <StyledImage alt={title} fluid={{ ...image, aspectRatio: 1 / 1 }} />
              </Image>
            </Link>
            <Link as={GatsbyLink} title={title} to={link?.url}>
              <P uppercase>
                <strong>{title}</strong>
              </P>
            </Link>
            <Small withSpacing={`xxs`}>
              {type}
              {type && variants?.[0]?.title ? `, ` : ``}
              {variants?.[0]?.title}
            </Small>
            <StyledRow gutter={`xs`} items={`center`} withSpacing={`xs`}>
              <StyledColumn gutter={`xs`} width={`1/2`}>
                <Small>
                  {locales?.additionalQuantity} {quantity}
                </Small>
              </StyledColumn>
              <StyledColumn gutter={`xs`} width={`1/2`}>
                <P align={`right`}>{variants?.[0]?.priceV2?.local}</P>
              </StyledColumn>
            </StyledRow>
          </StyledColumn>
        ))}
      </StyledRow>
      <StyledRule withSpacing={`sm`} />
      <StyledRow justify={`start`} gutter withWrap>
        <StyledColumn gutter width={`md:1/2`}>
          {shippingAddress && (
            <RichText withSpacing={`lg`}>
              <Overline large withSpacing={`sm`}>
                {locales?.additionalDelivery}
              </Overline>
              {(shippingAddress?.firstName || shippingAddress?.lastName) && (
                <P>{`${shippingAddress?.firstName || ``}${shippingAddress?.firstName && shippingAddress?.lastName ? ` ` : ``}${
                  shippingAddress?.lastName || ``
                }`}</P>
              )}
              {shippingAddress?.company && <P>{shippingAddress?.company}</P>}
              {shippingAddress?.address1 && <P>{shippingAddress?.address1}</P>}
              {shippingAddress?.address2 && <P>{shippingAddress?.address2}</P>}
              {shippingAddress?.city && <P>{shippingAddress?.city}</P>}
              {shippingAddress?.province && <P>{shippingAddress?.province}</P>}
              {(shippingAddress?.zip || shippingAddress?.country) && (
                <P>{`${shippingAddress?.zip || ``}${shippingAddress?.zip && shippingAddress?.country ? `, ` : ``}${
                  shippingAddress?.country || ``
                }`}</P>
              )}
              {shippingAddress?.phone && <P>{shippingAddress?.phone}</P>}
            </RichText>
          )}
        </StyledColumn>
        <StyledColumn gutter width={`md:1/2`}>
          <Overline large withSpacing={`sm`}>
            {locales?.additionalSummary}
          </Overline>
          <StyledRow justify={`start`} gutter={`xs`} withSpacing={`sm`}>
            <StyledColumn gutter={`xs`} width={`1/2`}>
              <P>{locales?.additionalSubtotal}</P>
            </StyledColumn>
            <StyledColumn gutter={`xs`} width={`1/2`}>
              <P align={`right`}>{subtotal || locales?.additionalFree}</P>
            </StyledColumn>
          </StyledRow>
          <StyledRow justify={`start`} gutter={`xs`} withSpacing={`sm`}>
            <StyledColumn gutter={`xs`} width={`1/2`}>
              <P>{locales?.additionalShipping}</P>
            </StyledColumn>
            <StyledColumn gutter={`xs`} width={`1/2`}>
              <P align={`right`}>{shipping || locales?.additionalFree}</P>
            </StyledColumn>
          </StyledRow>
          <StyledRow justify={`start`} gutter={`xs`}>
            <StyledColumn gutter={`xs`} width={`1/2`}>
              <P>
                <strong>{locales?.additionalTotal}</strong>
              </P>
            </StyledColumn>
            <StyledColumn gutter={`xs`} width={`1/2`}>
              <P align={`right`}>
                <strong>{total || locales?.additionalFree}</strong>
              </P>
            </StyledColumn>
          </StyledRow>
        </StyledColumn>
      </StyledRow>
      {link?.url && (
        <Help justify={`start`} items={`start`} gutter={`xxs`} withWrap>
          <StyledColumn gutter={`xxs`} width={`no-shrink`}>
            <H6 colour={`grey-darker`}>{locales?.additionalHelp}</H6>
          </StyledColumn>
          <StyledColumn gutter={`xxs`} width={`no-shrink`}>
            <StyledLink as={GatsbyLink} align={`left`} colour={`orange-dark`} size={`large`} title={link?.title} to={link?.url} underline>
              <span />
              <span>{link?.title}</span>
              <Icon name={`right`} width={12} />
            </StyledLink>
          </StyledColumn>
        </Help>
      )}
      {error && <StyledErrors>{error?.toString()}</StyledErrors>}
    </Order>
  )
)
