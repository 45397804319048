import React, { memo, useMemo } from "react"

import { useCustomerOrders } from "../../../hooks/useCustomer"
import { useRoutes } from "../../../hooks/useRoutes"

export const withAccountOrders = Component =>
  memo(({ name = "AccountOrders", location, page, template }: any) => {
    const { orders, loading, error } = useCustomerOrders(10, { reverse: true })
    const { routeResolver } = useRoutes()

    const link = routeResolver({ type: `orders` })

    const { id, token } = useMemo(() => {
      const parts = location?.pathname?.split(`/`) || []
      const id = parts?.length > 3 ? parts[parts?.length - 1] : null
      const token = location?.search || ""

      return { id, token }
    }, [location])

    Component.displayName = name
    return useMemo(
      () => <Component error={error} id={id} link={link} loading={loading} orders={orders} page={page} template={template} token={token} />,
      [error, id, link, loading, orders, page, template, token]
    )
  })
