import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { AccountOrders as Page } from "../../components/Account/Orders/AccountOrders"

export const query = graphql`
  query {
    page: sanityPageAccountOrders {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      help: _rawHelp(resolveReferences: { maxDepth: 6 })
      link: _rawLink(resolveReferences: { maxDepth: 6 })
      additionalNumber
      additionalDetail
      additionalBack
      additionalQuantity
      additionalEmpty
      additionalHelp
      additionalDelivery
      additionalSummary
      additionalSubtotal
      additionalShipping
      additionalTotal
      additionalFree
      additionalCount
      additionalCountPlural
      additionalDateOrdered
      additionalDateCancelled
      additionalStatusCancelled
      additionalStatusCancelledDescription
      additionalStatusFulfilled
      additionalStatusFulfilledDescription
      additionalStatusUnfulfilled
      additionalStatusUnfulfilledDescription
      additionalView
      additionalTrack
      additionalInvoice
    }
    template: sanityTemplateAccount {
      bannerTitle
      bannerText
      bannerBackground
      bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }) => <Page {...props} {...data} />)

export default Component
