import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledButton, StyledLink } from "../../../Styled/Button"
import { StyledRow } from "../../../Styled/Row"

export const Order = tw.figure`border-t border-grey pt-4.5 md:pb-6`
export const Help = tw(StyledRow)`mt-11 md:mt-17`
export const Link = tw(StyledLink)`block hover:opacity-100`
export const Colon = tw.span`hidden md:inline`
export const Button = styled(StyledButton)`
  ${tw`border-2 text-base py-0.5 md:py-2.75 md:border px-1 md:px-3 flex items-center text-center justify-center`}
  svg {
    ${tw`hidden md:block w-4 mr-2`}
  }
`
export const Image = styled.div`
  ${tw`relative bg-purple-dark-pastel mb-3`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
`
